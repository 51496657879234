import React, { useState, useEffect, useRef } from "react";

const testimonialData = [
  {
    id: 1,
    name: "Peter Willis",
    role: "CTO at Pernia Labs",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    text: "Working with Rajat was an absolute pleasure. His expertise in development helped us transform our application's performance. The code quality was impeccable, and he was always responsive to our needs.",
    rating: 5,
  },
  {
    id: 2,
    name: "Sarah Johnson",
    role: "Product Manager at Protein Bowl",
    image: "https://randomuser.me/api/portraits/women/2.jpg",
    text: "Rajat is a talented developer who delivered our project ahead of schedule with outstanding quality. His understanding of modern frontend technologies and best practices elevated our product significantly.",
    rating: 5,
  },
  {
    id: 3,
    name: "Michael Chen",
    role: "Founder at CryptoX",
    image: "https://randomuser.me/api/portraits/men/3.jpg",
    text: "I couldn't be happier with the results of our collaboration. Rajat not only implemented all the features we requested but also provided valuable suggestions that improved our user experience tremendously.",
    rating: 5,
  },
  {
    id: 4,
    name: "Sachin",
    role: "Founder Prime Visual Studio",
    image: "https://lh3.googleusercontent.com/i6St_YZpsiZkwQ2KWaehuSfCB3cq3mKzy1ivRyYDv-xPn94vbq2W3iShYgVSY0NcHNWIYXxnHdQkFV3pZpvAXEQ2PQ=w305-h305-n-l80-e31",
    text: "As a photographer, I don't have much experience with web development but Rajat made the whole process very smooth and delivered more than what we asked for, I've not seen a better photography website.",
    rating: 5,
  },
  {
    id: 5,
    name: "Emily Wright",
    role: "UI/UX Designer",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
    text: "As a designer, I appreciate developers who can bring designs to life exactly as envisioned. Rajat's attention to detail and commitment to pixel-perfect implementation made our collaboration seamless.",
    rating: 5,
  },
];

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const testimonialRef = useRef(null);

  // Automatic carousel
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % testimonialData.length);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  // Check if element is in viewport
  useEffect(() => {
    const handleScroll = () => {
      if (testimonialRef.current) {
        const rect = testimonialRef.current.getBoundingClientRect();
        const isInViewport =
          rect.top <=
            (window.innerHeight || document.documentElement.clientHeight) *
              0.75 && rect.bottom >= 0;

        if (isInViewport && !isVisible) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initial state

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible]);

  // Handle manual navigation
  const goToTestimonial = (index) => {
    setActiveIndex(index);
  };

  // Generate stars for rating
  const renderStars = (rating) => {
    return Array.from({ length: 5 }).map((_, index) => (
      <svg
        key={index}
        className={`w-5 h-5 ${
          index < rating ? "text-yellow-400" : "text-gray-300"
        }`}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    ));
  };

  return (
    <section
      id="testimonials"
      ref={testimonialRef}
      className="py-24 bg-gray-50 dark:bg-gray-800 overflow-hidden transition-colors duration-300"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div
          className={`text-center transition-opacity duration-1000 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <h2 className="text-3xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4 transition-colors duration-300">
            Client Testimonials
          </h2>
          
        </div>

        <div
          className={`relative mx-auto max-w-4xl transition-transform duration-1000 ${
            isVisible ? "translate-y-0" : "translate-y-16"
          }`}
        >
          {/* Testimonial Cards */}
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-700 ease-in-out"
              style={{ transform: `translateX(-${activeIndex * 100}%)` }}
            >
              {testimonialData.map((testimonial) => (
                <div key={testimonial.id} className="w-full flex-shrink-0 px-4">
                  <div className="bg-white dark:bg-gray-700 rounded-2xl shadow-xl p-8 md:p-10 transition-colors duration-300">
                    <div className="flex items-center gap-4 mb-6">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-16 h-16 rounded-full object-cover border-2 border-blue-500"
                      />
                      <div>
                        <h3 className="text-xl font-bold text-gray-900 dark:text-white transition-colors duration-300">
                          {testimonial.name}
                        </h3>
                        <p className="text-gray-600 dark:text-gray-300 transition-colors duration-300">
                          {testimonial.role}
                        </p>
                        <div className="flex mt-1">
                          {renderStars(testimonial.rating)}
                        </div>
                      </div>
                    </div>
                    <blockquote>
                      <p className="text-lg italic text-gray-700 dark:text-gray-200 transition-colors duration-300">
                        "{testimonial.text}"
                      </p>
                    </blockquote>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center mt-8 space-x-3">
            {testimonialData.map((_, index) => (
              <button
                key={index}
                onClick={() => goToTestimonial(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  activeIndex === index
                    ? "bg-blue-600 w-8"
                    : "bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500"
                }`}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}
          </div>

          {/* Navigation Arrows */}
          <div className="absolute top-1/2 left-4 right-4 flex justify-between items-center -mt-6 md:-mt-4">
            <button
              onClick={() =>
                goToTestimonial(
                  (activeIndex - 1 + testimonialData.length) %
                    testimonialData.length
                )
              }
              className="bg-white dark:bg-gray-800 rounded-full p-2 shadow-md text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white focus:outline-none transition-colors duration-300"
              aria-label="Previous testimonial"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <button
              onClick={() =>
                goToTestimonial((activeIndex + 1) % testimonialData.length)
              }
              className="bg-white dark:bg-gray-800 rounded-full p-2 shadow-md text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white focus:outline-none transition-colors duration-300"
              aria-label="Next testimonial"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Schema.org Markup for SEO */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement: testimonialData.map((testimonial, index) => ({
              "@type": "ListItem",
              position: index + 1,
              item: {
                "@type": "Review",
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: testimonial.rating,
                  bestRating: "5",
                },
                author: {
                  "@type": "Person",
                  name: testimonial.name,
                },
                reviewBody: testimonial.text,
              },
            })),
          }),
        }}
      />
    </section>
  );
};

export default Testimonials;
