// import Resume from "./Resume";
// import resumePDF from "../assets/Rajat_Saraswat_Resume.pdf";

import { useRef, useEffect } from "react";
import { motion } from "framer-motion";

const About = () => {
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const skillsRef = useRef(null);

  const skills = [
    {
      category: "Frontend",
      items: ["React", "JavaScript", "TypeScript", "Next.js", "Tailwind CSS"],
    },
    {
      category: "Backend",
      items: ["Node.js", "Express", "MongoDB", "REST APIs", "GraphQL"],
    },
    {
      category: "DevOps",
      items: ["AWS", "Docker", "Terraform", "CI/CD", "Git"],
    },
    {
      category: "Tools",
      items: ["VS Code", "Figma", "Postman", "Jest", "Webpack"],
    },
  ];

  useEffect(() => {
    // Force elements to be visible even if animations fail
    const timer = setTimeout(() => {
      if (titleRef.current) titleRef.current.style.opacity = "1";
      if (contentRef.current) contentRef.current.style.opacity = "1";
      if (skillsRef.current) skillsRef.current.style.opacity = "1";
    }, 1000);

    // Apply animations when component mounts
    if (titleRef.current) {
      titleRef.current.classList.add("animate-slide-in");
    }
    if (contentRef.current) {
      setTimeout(() => {
        contentRef.current.classList.add("animate-slide-in");
      }, 300);
    }
    if (skillsRef.current) {
      setTimeout(() => {
        skillsRef.current.classList.add("animate-slide-in");
      }, 600);
    }

    return () => clearTimeout(timer);
  }, []);

  return (
    <section
      id="about"
      className="py-12 min-h-screen w-full bg-gray-100 dark:bg-gray-800 sm:px-6 md:px-8 lg:px-16 transition-colors duration-300"
    >
      <div className="container mx-auto px-4 pt-16 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="w-full md:w-1/2 text-center">
            <h5
              ref={titleRef}
              className="text-lg text-blue-600 dark:text-blue-400 pb-4 font-bold opacity-0 transition-opacity duration-300"
            >
              ABOUT ME
            </h5>
            <h2 className="text-4xl font-bold text-gray-900 dark:text-white transition-colors duration-300">
              Experienced Front-end Developer based in Bangalore, India. 📍
            </h2>
            <div
              ref={contentRef}
              className="mt-8 text-left opacity-0 transition-opacity duration-300"
            >
              <p className="text-gray-700 dark:text-gray-300 transition-colors duration-300 text-center font-semibold mb-4">
                Developer | Technical Writer | Educator
              </p>
              <p className="text-gray-700 dark:text-gray-300 transition-colors duration-300">
                Frontend Developer with 3+ years of experience in building
                high-performance, responsive web applications using React.js,
                Next.js, JavaScript, and Tailwind CSS. AWS Cloud Practitioner
                badge for cloud computing and deployment strategies. Passionate
                about optimizing system performance and delivering seamless user
                experiences. Actively contribute to the developer community by
                sharing in-depth technical blogs on Hashnode and freeCodeCamp,
                helping developers master web dev and blockchain concepts.
              </p>

              <p className="mt-4 text-gray-700 dark:text-gray-300 transition-colors duration-300">
                Actively contribute to the developer community by sharing
                in-depth technical blogs on Hashnode and freeCodeCamp, helping
                developers master web dev and blockchain concepts.
              </p>
            </div>
          </div>
        </div>

        <div
          ref={skillsRef}
          className="mt-12 opacity-0 transition-opacity duration-500"
        >
          <h3 className="text-2xl font-bold text-center mb-8 text-gray-900 dark:text-white transition-colors duration-300">
            My Skills
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {skills.map((skillGroup, index) => (
              <motion.div
                key={skillGroup.category}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
              >
                <h4 className="text-xl font-bold text-gray-900 dark:text-white mb-4 transition-colors duration-300">
                  {skillGroup.category}
                </h4>
                <div className="flex flex-wrap gap-2">
                  {skillGroup.items.map((skill) => (
                    <span
                      key={skill}
                      className="px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 rounded-full text-sm font-medium transition-colors duration-300"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <a
          href="/assets/resume.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-6 py-2 px-4 bg-blue-600 text-white rounded-md font-bold text-lg shadow-md hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-600 transition duration-300"
        >
          Download Resume
        </a>
      </div>
    </section>
  );
};

export default About;
