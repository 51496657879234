import { useRef, useEffect, useState } from "react";
import ProjectCard from "./Cards/ProjectCard";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
// import pbLogo from "../assets/pbLogo1.png";

const Projects = () => {
  const titleRef = useRef(null);
  const projectsRef = useRef(null);
  const [current, setCurrent] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  const projects = [
    // {
    //   title: "React E-commerce",
    //   description: "A responsive e-commerce website built with React.",
    //   technologies: ["React", "Redux", "CSS", "JavaScript"],
    //   image:
    //     "https://pwpblog.wpengine.com/wp-content/uploads/2022/08/Ecommerce-Website-Promotion_Final.png",
    //   github: "https://github.com/your-username/project1",
    // },
    {
      title: "Protein Bowl",
      description: "A Protein rich and healthy food chain.",
      technologies: ["React", "Redux", "JavaScript", "Tailwind CSS"],
      image:
        "https://www.proteinbowl.in/static/media/GardenCobb.3c0c8cebeec0fa448585.jpg",
      github: "https://github.com/rvs9/protein-bowl",
      live: "https://www.proteinbowl.in",
    },
    {
      title: "Crypto X",
      description:
        "An easy to use crypto wallet for secure transactions of crypto currencies.",
      technologies: ["React", "Solidity", "REST", "JS", "Tailwind CSS"],
      image: "https://images.ctfassets.net/q33z48p65a6w/2lLSJ9g1lQXBLzqprDR8iE/2372fb32b016474077f34ef5c5202624/2204_BlogHeader_Wallet.png",
      github: "https://github.com/rvs9/cryptoX",
      live: "https://crypto-x-six.vercel.app/",
    },
    {
      title: "Food Delivery",
      description:
        "An online food delivery app to order from nearby restaurants.",
      technologies: ["React", "Redux", "CSS", "JavaScript"],
      image:
        "https://blog.ipleaders.in/wp-content/uploads/2019/11/foodmitho.jpg",
      github: "https://github.com/rvs9/Swiggy-clone-React",
    },
    // {
    //   title: "Weather App",
    //   description:
    //     "A mobile weather application that displays current weather data.",
    //   technologies: ["ReactNative", "REST", "JS", "CSS"],
    //   image: "https://cdn.jim-nielsen.com/ios/512/weather-2021-12-07.png",
    //   github: "https://github.com/rvs9/WeatherApp",
    // }
    // Add more projects here
  ];

  useEffect(() => {
    // Force elements to be visible even if animations fail
    const timer = setTimeout(() => {
      if (titleRef.current) titleRef.current.style.opacity = "1";
      if (projectsRef.current) projectsRef.current.style.opacity = "1";
    }, 1000);

    // Apply animations when component mounts
    if (titleRef.current) {
      titleRef.current.classList.add("animate-slide-in");
    }
    if (projectsRef.current) {
      setTimeout(() => {
        projectsRef.current.classList.add("animate-slide-in");
      }, 300);
    }

    return () => clearTimeout(timer);
  }, []);

  // Autoplay for carousel
  useEffect(() => {
    let interval;
    if (autoplay) {
      interval = setInterval(() => {
        setCurrent((current) =>
          current === projects.length - 1 ? 0 : current + 1
        );
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [autoplay, projects.length]);

  const nextSlide = () => {
    setCurrent(current === projects.length - 1 ? 0 : current + 1);
    setAutoplay(false); // Pause autoplay when manually navigating
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? projects.length - 1 : current - 1);
    setAutoplay(false); // Pause autoplay when manually navigating
  };

  return (
    <section
      id="projects"
      className="py-20 min-h-screen w-full bg-white dark:bg-gray-900 md:px-8 lg:px-16 flex flex-col items-center transition-colors duration-300"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2
          ref={titleRef}
          className="text-3xl md:text-5xl font-bold text-center text-gray-800 dark:text-white opacity-0 transition-opacity duration-300"
        >
          Projects
        </h2>
        <p className="mt-4 mb-4 text-center text-gray-600 dark:text-gray-400 transition-colors duration-300">
          Check out some of my recent work
        </p>

        {/* Mobile Carousel */}
        <div className="md:hidden relative">
          <div className="overflow-hidden rounded-lg shadow-lg">
            <div className="relative h-[500px]">
              <AnimatePresence mode="wait">
                <motion.div
                  key={current}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.5 }}
                  className="absolute inset-0"
                >
                  <ProjectCard project={projects[current]} />
                </motion.div>
              </AnimatePresence>

              {/* Navigation arrows */}
              <button
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/80 dark:bg-gray-800/80 rounded-full p-2 shadow-md z-10"
                onClick={prevSlide}
                aria-label="Previous slide"
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/80 dark:bg-gray-800/80 rounded-full p-2 shadow-md z-10"
                onClick={nextSlide}
                aria-label="Next slide"
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Desktop grid */}
        <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto mt-16">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} index={index} />
          ))}
        </div>

        <div className="flex justify-center mt-12">
          <Link
            to="/projects"
            className="px-6 py-3 bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-600 text-white rounded-lg font-bold shadow-md transition-colors duration-300 transform hover:scale-105"
          >
            View Portfolio
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Projects;
