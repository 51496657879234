import { Link } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import blogImage from "../assets/RPO-image-canva.jpg";
import datBlogImage from "../assets/DAT-blog-Image.png";
import blockchainBlogImage from "../assets/blockchain-blog-logo.png";
import { motion, AnimatePresence } from "framer-motion";

const Blog = () => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const blogCardsRef = useRef(null);
  const [visibleCards, setVisibleCards] = useState({});
  const cardRefs = useRef([]);

  // Carousel state
  const [current, setCurrent] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    // Force elements to be visible even if animations fail
    const timer = setTimeout(() => {
      if (titleRef.current) titleRef.current.style.opacity = "1";
      if (descriptionRef.current) descriptionRef.current.style.opacity = "1";
      if (blogCardsRef.current) blogCardsRef.current.style.opacity = "1";
    }, 1000);

    // Apply animations when component mounts
    if (titleRef.current) {
      titleRef.current.classList.add("animate-slide-in");
    }
    if (descriptionRef.current) {
      setTimeout(() => {
        descriptionRef.current.classList.add("animate-slide-in");
      }, 200);
    }
    if (blogCardsRef.current) {
      setTimeout(() => {
        blogCardsRef.current.classList.add("animate-slide-in");
      }, 400);
    }

    return () => clearTimeout(timer);
  }, []);

  const blogs = [
    {
      id: 1,
      title: "Top Performance Optimizing Techniques",
      description:
        "As a developer, it's essential to optimize your apps to deliver the best user experience. In this blog post, we will explore various performance optimization techniques in React to help you understand and implement them effectively.",
      image: blogImage,
      link: "/blog/Performance-Optimization-in-React",
    },
    {
      id: 2,
      title: "Debouncing and Throttling in JavaScript",
      description:
        "Whether you're dealing with frequent events or resource-intensive operations, debouncing and throttling can be powerful techniques to optimize performance and enhance the user experience. So, let's dive in and master these JS concepts.",
      image: datBlogImage,
      link: "/blog/debounce-and-throttle",
    },
    {
      id: 3,
      title: "Build a Blockchain Transactions App With React",
      description:
        "In this blog post, we will explore the steps to create a blockchain transactions app using React, including connecting to a blockchain network and displaying transaction data.",
      image: blockchainBlogImage,
      link: "https://rajatdev9.hashnode.dev/react-19-understanding-the-latest-game-changing-features",
    },
    
  ];

  useEffect(() => {
    // Initialize card refs array
    cardRefs.current = cardRefs.current.slice(0, 3);

    // Handle scroll to animate cards
    const handleScroll = () => {
      cardRefs.current.forEach((ref, index) => {
        if (ref && isElementInViewport(ref) && !visibleCards[index]) {
          setVisibleCards((prev) => ({ ...prev, [index]: true }));
        }
      });
    };

    // Check if element is in viewport
    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight) *
            0.85 && rect.bottom >= 0
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initial state

    return () => window.removeEventListener("scroll", handleScroll);
  }, [visibleCards, blogs.length]);

  // Autoplay for carousel
  useEffect(() => {
    let interval;
    if (autoplay) {
      interval = setInterval(() => {
        setCurrent((current) =>
          current === blogs.length - 1 ? 0 : current + 1
        );
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [autoplay, blogs.length]);

  // Create ref for each blog card
  const setCardRef = (index, el) => {
    cardRefs.current[index] = el;
  };

  // Navigate to next or previous slide
  const nextSlide = () => {
    setCurrent(current === blogs.length - 1 ? 0 : current + 1);
    setAutoplay(false); // Pause autoplay when manually navigating
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? blogs.length - 1 : current - 1);
    setAutoplay(false); // Pause autoplay when manually navigating
  };

  return (
    <section
      id="blogs"
      className="py-20 min-h-screen w-full bg-gray-100 dark:bg-gray-800 sm:px-6 md:px-8 lg:px-16 flex flex-col items-center transition-colors duration-300"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2
          ref={titleRef}
          className="text-3xl md:text-5xl font-bold text-center text-gray-800 dark:text-white opacity-0 transition-opacity duration-300"
        >
          Blog
        </h2>
        <p
          ref={descriptionRef}
          className="mt-4 text-lg md:text-2xl text-center text-gray-800 dark:text-gray-300 opacity-0 transition-opacity duration-300"
        >
          Stay up-to-date with the latest trends and insights in web dev tech!
        </p>
        <div
          ref={blogCardsRef}
          className="mt-12 opacity-0 transition-opacity duration-500"
        >
          {/* Mobile Carousel */}
          <div className="md:hidden relative">
            <div className="overflow-hidden rounded-lg shadow-lg">
              <div className="relative h-[500px]">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={current}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                    className="absolute inset-0"
                  >
                    <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden h-full transition-all duration-300">
                      <img
                        src={blogs[current].image}
                        alt={blogs[current].title}
                        className="w-full h-48 object-cover transition-transform duration-500"
                      />
                      <div className="p-6">
                        <h3 className="text-xl font-bold text-gray-800 dark:text-white transition-colors duration-300">
                          {blogs[current].title}
                        </h3>
                        <p className="mt-3 text-base text-gray-600 dark:text-gray-300 transition-colors duration-300">
                          {blogs[current].description}
                        </p>
                        <div className="mt-6">
                          <Link
                            className="py-2 px-4 bg-blue-600 text-white rounded-md font-bold text-lg shadow-md hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-300"
                            to={blogs[current].link}
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </AnimatePresence>

                {/* Navigation arrows */}
                <button
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/80 dark:bg-gray-800/80 rounded-full p-2 shadow-md z-10"
                  onClick={prevSlide}
                  aria-label="Previous slide"
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/80 dark:bg-gray-800/80 rounded-full p-2 shadow-md z-10"
                  onClick={nextSlide}
                  aria-label="Next slide"
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {/* Dots indicator */}
            <div className="flex justify-center mt-4">
              {blogs.map((_, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setCurrent(index);
                    setAutoplay(false);
                  }}
                  className={`mx-1 h-3 w-3 rounded-full transition-colors duration-300 ${
                    current === index
                      ? "bg-blue-600"
                      : "bg-gray-300 dark:bg-gray-600"
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>

          {/* Desktop grid */}
          <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogs.map((blog, index) => (
              <div
                key={blog.id}
                ref={(el) => setCardRef(index, el)}
                className={`bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden transition-all duration-700 transform ${
                  visibleCards[index]
                    ? "opacity-100 translate-y-0 rotate-0 scale-100"
                    : "opacity-0 translate-y-8 scale-95"
                } hover:shadow-xl hover:-translate-y-1`}
                style={{ transitionDelay: `${index * 150}ms` }}
              >
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="rounded-t-lg w-full h-48 object-cover transition-transform duration-500 hover:scale-105"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-800 dark:text-white transition-colors duration-300">
                    {blog.title}
                  </h3>
                  <p className="mt-3 text-base text-gray-600 dark:text-gray-300 transition-colors duration-300">
                    {blog.description}
                  </p>
                  <div className="mt-6">
                    <Link
                      className="py-2 px-4 bg-blue-600 text-white rounded-md font-bold text-lg shadow-md hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-300"
                      to={blog.link}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <Link
          to="/blog"
          className="px-6 py-3 bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-600 text-white rounded-lg font-bold shadow-md transition-colors duration-300 transform hover:scale-105"
        >
          View All Blog Posts
        </Link>
      </div>
    </section>
  );
};

export default Blog;
