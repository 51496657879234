import React, { useState, useEffect, useRef, useMemo } from "react";
import ProjectCard from "./Cards/ProjectCard";
import Testimonials from "./Testimonials";
import { motion } from "framer-motion";

const ProjectsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [readingProgress, setReadingProgress] = useState(0);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isVisible, setIsVisible] = useState(false);

  const searchRef = useRef(null);
  const projectsRef = useRef(null);
  const projectCardRefs = useRef([]);

  // Wrap projects in useMemo
  const projects = useMemo(
    () => [
      {
        id: 1,
        title: "Protein Bowl",
        description: "A Protein rich and healthy food chain.",
        technologies: ["React", "Redux", "JavaScript", "Tailwind CSS"],
        image:
          "https://www.proteinbowl.in/static/media/GardenCobb.3c0c8cebeec0fa448585.jpg",
        github: "https://www.proteinbowl.in",
        live: "https://www.proteinbowl.in",
        category: "Web App",
        year: "2023",
      },
      {
        id: 2,
        title: "Crypto X",
        description:
          "An easy to use crypto wallet for secure transactions of crypto currencies.",
        technologies: ["React", "Solidity", "REST", "JS", "Tailwind CSS"],
        image: "https://images.ctfassets.net/q33z48p65a6w/2lLSJ9g1lQXBLzqprDR8iE/2372fb32b016474077f34ef5c5202624/2204_BlogHeader_Wallet.png",
        github: "https://github.com/rvs9/cryptoX",
        live: "https://crypto-x-six.vercel.app/",
        category: "Web App",
        year: "2024",
      },
     
      {
        id: 3,
        title: "Food Delivery",
        description:
          "An online food delivery app to order from nearby restaurants.",
        technologies: ["React", "Redux", "CSS", "JavaScript"],
        image:
          "https://blog.ipleaders.in/wp-content/uploads/2019/11/foodmitho.jpg",
        github: "https://github.com/rvs9/Swiggy-clone-React",
        category: "Web App",
        year: "2022",
      },
      {
        id: 4,
        title: "Task Management Dashboard",
        description:
          "A productivity tool for teams to manage tasks and projects.",
        technologies: ["React", "Node.js", "MongoDB", "Express"],
        image:
          "https://colorlib.com/wp/wp-content/uploads/sites/2/free-dashboard-templates.jpg",
        github: "https://github.com/rvs9/task-manager",
        live: "https://taskmanager.example.com",
        category: "Full Stack",
        year: "2023",
      },
      {
        id: 5,
        title: "E-commerce Platform",
        description:
          "A fully functional online store with payment integration.",
        technologies: ["Next.js", "Stripe", "Tailwind CSS", "MongoDB"],
        image:
          "https://colorlib.com/wp/wp-content/uploads/sites/2/ecommerce-website-templates.jpg",
        github: "https://github.com/rvs9/ecommerce-platform",
        live: "https://ecommerce.example.com",
        category: "Full Stack",
        year: "2023",
      },
      {
        id: 6,
        title: "Weather App",
        description:
          "A mobile weather application that displays current weather data.",
        technologies: ["ReactNative", "REST", "JS", "CSS"],
        image: "https://cdn.jim-nielsen.com/ios/512/weather-2021-12-07.png",
        github: "https://github.com/rvs9/WeatherApp",
        category: "Mobile App",
        year: "2022",
      },
    ],
    []
  ); // Empty dependency array to memoize the projects

  // Get unique categories
  const categories = [
    "All",
    ...new Set(projects.map((project) => project.category)),
  ];

  // Filter projects based on search term and category
  useEffect(() => {
    const results = projects.filter((project) => {
      const matchesSearch =
        project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.technologies.some((tech) =>
          tech.toLowerCase().includes(searchTerm.toLowerCase())
        );

      const matchesCategory =
        selectedCategory === "All" || project.category === selectedCategory;

      return matchesSearch && matchesCategory;
    });

    setFilteredProjects(results);
  }, [searchTerm, selectedCategory, projects]);

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      // Update reading progress
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setReadingProgress(progress);

      // Show/hide back to top button
      setShowBackToTop(window.scrollY > 300);

      // Check for project section visibility
      if (projectsRef.current) {
        const rect = projectsRef.current.getBoundingClientRect();
        const isInViewport =
          rect.top <=
            (window.innerHeight || document.documentElement.clientHeight) *
              0.75 && rect.bottom >= 0;

        if (isInViewport && !isVisible) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initial state

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible, projects]);

  // Initial load
  useEffect(() => {
    setFilteredProjects(projects);

    // Force search element to be visible
    if (searchRef.current) {
      setTimeout(() => {
        searchRef.current.style.opacity = "1";
      }, 500);
    }

    // Initialize project refs
    projectCardRefs.current = projectCardRefs.current.slice(0, projects.length);
  }, [projects]);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20 pb-12 transition-colors duration-300">
      {/* Reading Progress Bar */}
      <div
        className="fixed top-0 left-0 right-0 h-1 bg-blue-600 z-50 transition-all duration-300 ease-out"
        style={{ width: `${readingProgress}%` }}
      ></div>

      {/* Back to Top Button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 p-3 rounded-full bg-blue-600 text-white shadow-lg transform transition-all duration-300 ${
          showBackToTop
            ? "translate-y-0 opacity-100"
            : "translate-y-16 opacity-0"
        }`}
        aria-label="Scroll to top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          />
        </svg>
      </button>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4 transition-colors duration-300">
            My Projects
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto transition-colors duration-300">
            A collection of my work showcasing my skills and experience
          </p>
        </div>

        {/* Search and Filter Section */}
        <div
          ref={searchRef}
          className="mb-12 max-w-3xl mx-auto opacity-0 transition-opacity duration-500"
        >
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-colors duration-300">
            <div className="relative mb-6">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search projects by title, tech stack, or description..."
                className="pl-10 pr-4 py-3 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 transition-colors duration-300"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="flex flex-wrap gap-2">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    selectedCategory === category
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600"
                  }`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Results Count */}
        <div className="mb-8 text-lg text-gray-600 dark:text-gray-300 transition-colors duration-300">
          {filteredProjects.length === 0 ? (
            <p>No projects found. Try a different search term or category.</p>
          ) : (
            <p>
              Found {filteredProjects.length} project
              {filteredProjects.length !== 1 ? "s" : ""}
            </p>
          )}
        </div>

        {/* Projects Grid */}
        <div
          ref={projectsRef}
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16 transition-opacity duration-1000 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          {filteredProjects.map((project, index) => (
            <motion.div
              key={project.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <ProjectCard project={project} index={index} />
            </motion.div>
          ))}
        </div>

        {/* Testimonials Section */}
        <Testimonials />
      </div>

      {/* Schema.org markup for SEO */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement: projects.map((project, index) => ({
              "@type": "SoftwareApplication",
              position: index + 1,
              name: project.title,
              description: project.description,
              applicationCategory: project.category,
              operatingSystem: "Web",
              datePublished: `${project.year}-01-01`,
              offers: {
                "@type": "Offer",
                price: "0",
                priceCurrency: "USD",
              },
            })),
          }),
        }}
      />
    </div>
  );
};

export default ProjectsPage;
