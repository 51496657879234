import React from "react";
import ReactDOM from "react-dom/client";
import // BrowserRouter,
// createBrowserRouter,
// RouterProvider,
"react-router-dom";
//import "./index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
// import Main from "./components/Main";
// import Blog from "./components/Blog";
// import GraphQL from "./components/Blogs/GraphQL";
// import Home from "./components/Home";
// import ReactHooks from "./components/Blogs/ReactHooks";
// import PerformanceOptimizationBlog from "./components/Blogs/PerformanceOptimizationBlog";
// import DebouncingAndThrottlingBlog from "./components/Blogs/DebounceAndThrottling";

// const appRouter = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     children: [
//       {
//         path: "/",
//         element: <Main />,
//       },
//       {
//         path: "blogs",
//         element: <Blog />,
//       },
//       {
//         path: "home",
//         element: <Home />,
//       },
//       {
//         path: "graphQL",
//         element: <GraphQL />,
//       },
//       {
//         path: "blogs/graphQL",
//         element: <GraphQL />,
//       },
//       {
//         path: "reactHooks",
//         element: <ReactHooks />,
//       },
//       {
//         path: "blogs/reactHooks",
//         element: <ReactHooks />,
//       },
//       {
//         path: "Performance-Optimization-in-React",
//         element: <PerformanceOptimizationBlog />,
//       },
//       {
//         path: "blogs/Performance-Optimization-in-React",
//         element: <PerformanceOptimizationBlog />,
//       },
//       {
//         path: "/debounce-and-throttle",
//         element: <DebouncingAndThrottlingBlog />,
//       },
//       {
//         path: "blogs/debounce-and-throttle",
//         element: <DebouncingAndThrottlingBlog />,
//       },
//     ],
//   },
// ]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
