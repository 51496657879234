import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    // Force element to be visible immediately without animation
    if (cardRef.current) {
      cardRef.current.style.opacity = "1";
      cardRef.current.style.transform = "translateY(0)";
    }
  }, []);

  return (
    <div
      ref={cardRef}
      className="flex flex-col h-full bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
      style={{ opacity: 1, transform: "translateY(0)" }}
    >
      <div className="relative overflow-hidden group">
        <img
          src={project.image}
          alt={project.title}
          className="w-full h-48 object-cover transition-transform duration-500 group-hover:scale-105"
        />
      </div>

      <div className="p-6 flex-grow flex flex-col">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-xl font-bold text-gray-900 dark:text-white transition-colors duration-300">
            {project.title}
          </h3>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {project.year}
          </span>
        </div>
        <p className="text-gray-700 dark:text-gray-300 text-base mb-4 flex-grow transition-colors duration-300">
          {project.description}
        </p>
        <div className="flex flex-wrap gap-2 mb-8">
          {project.technologies.map((technology) => (
            <span
              key={technology}
              className="inline-block bg-gray-200 dark:bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 dark:text-gray-300 transition-colors duration-300"
            >
              {technology}
            </span>
          ))}
        </div>
        <div className="flex space-x-2">
          <Link
            to={project.github}
            className="bg-gray-800 text-white px-3 py-1 rounded-md text-sm hover:bg-gray-700 transition-colors duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </Link>
          {project.live && (
            <Link
              to={project.live}
              className="bg-blue-600 text-white px-3 py-1 rounded-md text-sm hover:bg-blue-700 transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Demo
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
