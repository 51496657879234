import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/tailwind.css";

import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import BackToTop from "./components/BackToTop";
import ProjectsPage from "./components/ProjectsPage";
import Main from "./components/Main";
import BlogPage from "./components/BlogPage";
import BlogPost from "./components/Blogs/BlogPost";
import PerformanceOptimizationPost from "./components/Blogs/PerformanceOptimizationBlog";
import DebounceAndThrottle from "./components/Blogs/DebounceAndThrottling";
import ReactHooks from "./components/Blogs/ReactHooks";

// Create Theme Context
export const ThemeContext = createContext();

function App() {
  // Initialize theme from localStorage or default to light
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark";
  });

  // Update localStorage when theme changes
  useEffect(() => {
    localStorage.setItem("theme", darkMode ? "dark" : "light");
    // Apply dark mode to document
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  // Toggle theme function
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
      <div className={`App ${darkMode ? "dark" : ""}`}>
        <Router>
          <NavBar />
          <TransitionGroup>
            <CSSTransition classNames="fade" timeout={300}>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route
                  path="/blog/Performance-Optimization-in-React"
                  element={<PerformanceOptimizationPost />}
                />
                <Route
                  path="/blog/debounce-and-throttle"
                  element={<DebounceAndThrottle />}
                />
                <Route path="/blog/react-hooks" element={<ReactHooks />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
          <BackToTop />
        </Router>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
