import { useRef, useEffect, useState } from "react";
// import linkedInLogo from "../assets/linkedin.png";
// import gitLogoW from "../assets/github.png";
// import twitterLogo from "../assets/twitter.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const formRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    // Force elements to be visible even if animations fail
    const timer = setTimeout(() => {
      if (titleRef.current) titleRef.current.style.opacity = "1";
      if (formRef.current) formRef.current.style.opacity = "1";
    }, 1000);

    // Apply animations when component mounts
    if (titleRef.current) {
      titleRef.current.classList.add("animate-slide-in");
    }
    if (formRef.current) {
      setTimeout(() => {
        formRef.current.classList.add("animate-slide-in");
      }, 300);
    }

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate form submission
    setTimeout(() => {
      setIsSubmitting(false);
      setSubmitMessage({
        type: "success",
        text: "Thank you for your message! I'll get back to you soon.",
      });
      setFormData({ name: "", email: "", message: "" });

      // Clear success message after 5 seconds
      setTimeout(() => {
        setSubmitMessage(null);
      }, 5000);
    }, 1500);
  };

  return (
    <section
      id="contact"
      className="py-16 bg-white dark:bg-gray-900 md:px-8 lg:px-16 transition-colors duration-300"
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2
          ref={titleRef}
          className="text-3xl md:text-5xl font-bold text-center text-blue-600 dark:text-blue-400 opacity-0 transition-opacity duration-300"
        >
          Contact Me
        </h2>
        <p className="text-lg md:text-xl text-center text-gray-800 dark:text-gray-200 mt-4 transition-colors duration-300">
          Have a question or want to work together? Feel free to reach out!👇
        </p>

        <div
          ref={formRef}
          className="mt-12 max-w-2xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 opacity-0 transition-opacity duration-300"
        >
          {submitMessage ? (
            <div
              className={`p-4 rounded-md ${
                submitMessage.type === "success"
                  ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                  : "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100"
              }`}
            >
              {submitMessage.text}
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 transition-colors duration-300"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors duration-300"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 transition-colors duration-300"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors duration-300"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 transition-colors duration-300"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors duration-300"
                ></textarea>
              </div>

              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-300 ${
                    isSubmitting ? "opacity-75 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
          )}
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center mt-12">
          <div className="max-w-sm rounded overflow-hidden shadow-lg mx-4 mb-8 md:mb-0 bg-white dark:bg-gray-800 transition-colors duration-300">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 text-gray-800 dark:text-white transition-colors duration-300">
                Email
              </div>
              <a
                className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300"
                href="mailto:saraswatrajat96@gmail.com"
              >
                saraswatrajat96@gmail.com
              </a>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden shadow-lg mx-4 bg-white dark:bg-gray-800 transition-colors duration-300">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 text-gray-800 dark:text-white transition-colors duration-300">
                Phone
              </div>
              <p className="text-gray-700 dark:text-gray-300 transition-colors duration-300">
                +91 7060 322 330
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
