import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";

import dp from "../assets/DP11.jpeg";

import linkedInLogo from "../assets/linkedin.png";
import gitLogoW from "../assets/github.png";
import twitterLogo from "../assets/twitter.png";
import hashnodeLogo from "../assets/hashnode.png";
import freeCodeCampLogo from "../assets/freeCodeCamp.png";

import htmlLogo from "../assets/html-icon.svg";
import awsLogo from "../assets/AWS2.png";
import jsLogo from "../assets/javascript-icon.svg";
import tsLogo from "../assets/typescript.png";
import reactLogo from "../assets/react-icon.svg";
import nextLogo from "../assets/next-logo.png";
import reduxLogo from "../assets/redux-logo.png";
import tailwindLogo from "../assets/tailwind-icon.svg";

const Home = () => {
  const headingRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const techStackRef = useRef(null);

  useEffect(() => {
    // Force elements to be visible even if animations fail
    const timer = setTimeout(() => {
      if (headingRef.current) headingRef.current.style.opacity = "1";
      if (descriptionRef.current) descriptionRef.current.style.opacity = "1";
      if (imageRef.current) imageRef.current.style.opacity = "1";
      if (techStackRef.current) techStackRef.current.style.opacity = "1";
    }, 1000);

    // Apply animations when component mounts
    if (headingRef.current) {
      headingRef.current.classList.add("animate-slide-in");
    }
    if (descriptionRef.current) {
      setTimeout(() => {
        descriptionRef.current.classList.add("animate-slide-in");
      }, 200);
    }
    if (imageRef.current) {
      setTimeout(() => {
        imageRef.current.classList.add("animate-fade-in");
      }, 400);
    }
    if (techStackRef.current) {
      setTimeout(() => {
        techStackRef.current.classList.add("animate-slide-in");
      }, 600);
    }

    return () => clearTimeout(timer);
  }, []);

  // Create animations
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 10,
      },
    },
  };

  const imageVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        delay: 0.3,
      },
    },
    hover: {
      scale: 1.05,
      rotate: 2,
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
  };

  const socialButtonVariants = {
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        stiffness: 400,
      },
    },
    tap: {
      scale: 0.9,
    },
  };

  return (
    <section
      id="home"
      className="py-7 min-h-screen w-full bg-white dark:bg-gray-900 md:px-8 lg:px-16 transition-colors duration-300"
    >
      <div className="container mx-auto md:mx-36 flex flex-col md:flex-row items-center justify-center pt-16">
        <motion.div
          className="w-full md:w-1/2 text-center md:text-left md:mr-16"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.h2
            ref={headingRef}
            variants={itemVariants}
            className="text-4xl md:text-6xl font-bold text-gray-900 dark:text-white opacity-0 transition-opacity duration-300"
          >
            Front-End React Developer
          </motion.h2>
          <motion.p
            ref={descriptionRef}
            variants={itemVariants}
            className="mt-6 text-base mx-auto px-4 md:text-lg text-gray-700 dark:text-gray-300 opacity-0 transition-opacity duration-300"
          >
            Hi, I'm Rajat Saraswat, an experienced Front-end React Developer
            based in Bangalore, India. 📍
          </motion.p>
          <motion.div
            className="flex mt-4 justify-center md:justify-start"
            variants={containerVariants}
          >
            <motion.a
              href="https://www.linkedin.com/in/rajatsaraswat9/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mt-4 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
              variants={socialButtonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <img src={linkedInLogo} alt="LinkedIn" className="h-7 w-7 mr-2" />
            </motion.a>
            <motion.a
              href="https://github.com/rvs9"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mt-4 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
              variants={socialButtonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <img
                src={gitLogoW}
                alt="github"
                className="h-11 w-11 rounded-full mr-2"
              />
            </motion.a>
            <motion.a
              href="https://twitter.com/rajatsaraswat7"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mt-4 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
              variants={socialButtonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <img src={twitterLogo} alt="twitter" className="h-10 w-10 mr-2" />
            </motion.a>
            <motion.a
              href="https://rajatdev9.hashnode.dev/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mt-4 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
              variants={socialButtonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <img src={hashnodeLogo} alt="hashnode" className="h-8 w-8 mr-2" />
            </motion.a>
            <motion.a
              href="https://hashnode.com/@Rajats9"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mt-4 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
              variants={socialButtonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <img
                src={freeCodeCampLogo}
                alt="freeCodeCamp"
                className="h-8 w-8 mr-2 rounded-md"
              />
            </motion.a>
          </motion.div>
        </motion.div>

        <div className="md:w-1/2 mt-8 md:mt-0">
          <motion.img
            ref={imageRef}
            src={dp}
            alt="profile"
            className="w-2/3 md:w-full pt-4 max-w-sm mx-auto md:ml-0 rounded-full shadow-lg opacity-0 transition-opacity duration-500"
            variants={imageVariants}
            initial="hidden"
            animate="visible"
            whileHover="hover"
          />
        </div>
      </div>

      <div
        ref={techStackRef}
        className="flex flex-col items-center justify-center mx-4 md:mx-36 mt-16 md:flex-row md:items-center md:justify-start opacity-0 transition-opacity duration-500"
      >
        <p className="mr-4 font-bold text-center md:text-left text-gray-900 dark:text-white transition-colors duration-300">
          Tech Stack |
        </p>
        <div className="flex flex-wrap items-center justify-center mt-2">
          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={htmlLogo}
              alt="HTML"
              className="h-8 w-8 md:h-8 md:w-8 object-contain"
            />
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={jsLogo}
              alt="JavaScript"
              className="h-8 w-8 md:h-8 md:w-8 object-contain"
            />
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={tsLogo}
              alt="TypeScript"
              className="h-8 w-8 md:h-8 md:w-8 object-contain"
            />
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={reactLogo}
              alt="React"
              className="h-8 w-8 object-contain"
            />
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={nextLogo}
              alt="Next.js"
              className="h-8 w-8 md:h-8 md:w-8 object-contain"
            />
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={reduxLogo}
              alt="Redux"
              className="h-8 w-8 object-contain"
            />
          </div>
          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={tailwindLogo}
              alt="Tailwind CSS"
              className="h-8 w-8 object-contain"
            />
          </div>
          <div className="bg-white dark:bg-gray-800 rounded-full shadow-lg p-2 md:p-4 mx-2 md:mx-1 my-2 transition-colors duration-300 hover:transform hover:scale-110">
            <img
              src={awsLogo}
              alt="AWS"
              className="h-8 w-8 md:h-8 md:w-8 object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
