import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import blogImage from "../assets/RPO-image-canva.jpg";
import datBlogImage from "../assets/DAT-blog-Image.png";
import graphQLBlogImage from "../assets/graphQL-blog-image.png";
import reactHooksImage from "../assets/reactHooks-Image2.jpg";

const BlogPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [readingProgress, setReadingProgress] = useState(0);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [visibleElements, setVisibleElements] = useState({});

  const searchRef = useRef(null);
  const blogRefs = useRef([]);

  // Wrap blogs in useMemo
  const blogs = useMemo(
    () => [
      {
        id: 1,
        title: "Understanding React Hooks",
        excerpt:
          "React Hooks provide a more elegant and functional approach to managing state, side effects, and other React features within functional components.",
        description:
          "Let's dive in and discover how React Hooks can offer a cleaner and more concise way to write React code, making it easier to understand, maintain, and test.",
        image: reactHooksImage,
        date: "May 10, 2023",
        author: "Rajat Saraswat",
        readTime: "8 min read",
        link: "/blog/react-hooks",
        category: "React",
        tags: ["React", "Hooks", "Frontend"],
      },
      {
        id: 2,
        title: "Top Performance Optimizing Techniques",
        excerpt:
          "As a React developer, it's essential to optimize your applications to deliver the best user experience.",
        description:
          "In this blog post, we will explore various performance optimization techniques in React to help you understand and implement them effectively.",
        image: blogImage,
        date: "April 22, 2023",
        author: "Rajat Saraswat",
        readTime: "10 min read",
        link: "/blog/Performance-Optimization-in-React",
        category: "Performance",
        tags: ["React", "Performance", "Optimization"],
      },
      {
        id: 3,
        title: "Debouncing and Throttling in JavaScript",
        excerpt:
          "Whether you're dealing with frequent events or resource-intensive operations, debouncing and throttling can be powerful techniques.",
        description:
          "These techniques optimize performance and enhance the user experience. So, let's dive in and master these JS concepts.",
        image: datBlogImage,
        date: "March 15, 2023",
        author: "Rajat Saraswat",
        readTime: "7 min read",
        link: "/blog/debounce-and-throttle",
        category: "JavaScript",
        tags: ["JavaScript", "Performance", "Frontend"],
      },
      {
        id: 4,
        title: "Introduction to GraphQL",
        excerpt:
          "GraphQL is a query language for APIs that provides a more efficient, powerful and flexible alternative to REST.",
        description:
          "Learn how GraphQL can help you fetch exactly the data you need in a single request, making your applications faster and more efficient.",
        image: graphQLBlogImage,
        date: "February 8, 2023",
        author: "Rajat Saraswat",
        readTime: "9 min read",
        link: "/blog/graphQL",
        category: "API",
        tags: ["GraphQL", "API", "Backend"],
      },
      {
        id: 5,
        title: "Build a Blockchain Transactions App With React",
        excerpt:
          "Learn how to build a decentralized application that interacts with blockchain transactions using React.",
        description:
          "In this blog post, we will explore the steps to create a blockchain transactions app using React, including connecting to a blockchain network and displaying transaction data.",
        image:
          "https://cdn.hashnode.com/res/hashnode/image/upload/v1729629953103/ec32e3a8-a185-4a24-a4e4-e0602834ad58.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
        date: "June 1, 2024",
        author: "Rajat Saraswat",
        readTime: "26 min read",
        link: "https://rajatdev9.hashnode.dev/build-a-blockchain-transactions-app-with-react",
        category: "Blockchain",
        tags: ["Blockchain", "React", "Web Development"],
      },
      {
        id: 6,
        title: "React 19: Understanding the Latest Game Changing Features",
        excerpt:
          "Explore the new features introduced in React 19 that can significantly improve your development workflow.",
        description:
          "This blog post covers the latest updates in React 19, including performance improvements, new hooks, and best practices for leveraging these features in your applications.",
        image:
          "https://cdn.hashnode.com/res/hashnode/image/upload/v1726145471285/7273b8ea-d41f-49dd-b74b-b26e46966de7.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
        date: "Oct 15, 2024",
        author: "Rajat Saraswat",
        readTime: "10 min read",
        link: "https://rajatdev9.hashnode.dev/react-19-understanding-the-latest-game-changing-features",
        category: "React",
        tags: ["React", "JavaScript", "Web Development"],
      },
      {
        id: 7,
        title: "How to Use Etherscan: A Beginner's Guide",
        excerpt:
          "Learn how to navigate and utilize Etherscan to track Ethereum transactions and smart contracts.",
        description:
          "In this guide, we will walk you through the features of Etherscan, how to search for transactions, and how to analyze smart contracts on the Ethereum blockchain.",
        image:
          "https://cdn.hashnode.com/res/hashnode/image/upload/v1729967804447/3d758250-6537-43eb-87ca-4e54156f1d65.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
        date: "Nov 1, 2024",
        author: "Rajat Saraswat",
        readTime: "8 min read",
        link: "https://rajatdev9.hashnode.dev/how-to-use-etherscan-a-beginners-guide",
        category: "Blockchain",
        tags: ["Etherscan", "Blockchain", "Ethereum"],
      },
    ],
    []
  ); // Empty dependency array to memoize the blogs

  // Get unique categories
  const categories = ["All", ...new Set(blogs.map((blog) => blog.category))];

  // Filter blogs based on search term and category
  useEffect(() => {
    const results = blogs.filter((blog) => {
      const matchesSearch =
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.tags.some((tag) =>
          tag.toLowerCase().includes(searchTerm.toLowerCase())
        );

      const matchesCategory =
        selectedCategory === "All" || blog.category === selectedCategory;

      return matchesSearch && matchesCategory;
    });

    setFilteredBlogs(results);
  }, [searchTerm, selectedCategory, blogs]);

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      // Update reading progress
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setReadingProgress(progress);

      // Show/hide back to top button
      setShowBackToTop(window.scrollY > 500);

      // Check for elements to animate on scroll
      blogRefs.current.forEach((ref, index) => {
        if (ref && isElementInViewport(ref)) {
          setVisibleElements((prev) => ({ ...prev, [index]: true }));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [blogs]);

  // Initial load
  useEffect(() => {
    setFilteredBlogs(blogs);

    // Initialize blog refs
    blogRefs.current = blogRefs.current.slice(0, blogs.length);

    // Force search element to be visible
    if (searchRef.current) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.style.opacity = "1";
        }
      }, 500);
    }
  }, [blogs]);

  // Check if element is in viewport
  const isElementInViewport = (el) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Set a ref for a blog card
  const setBlogRef = (index, element) => {
    blogRefs.current[index] = element;
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20 pb-12 transition-colors duration-300">
      {/* Reading Progress Bar */}
      <div
        className="fixed top-0 left-0 right-0 h-1 bg-blue-600 z-50 transition-all duration-300 ease-out"
        style={{ width: `${readingProgress}%` }}
      ></div>

      {/* Back to Top Button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 p-3 rounded-full bg-blue-600 text-white shadow-lg transform transition-all duration-300 ${
          showBackToTop
            ? "translate-y-0 opacity-100"
            : "translate-y-16 opacity-0"
        }`}
        aria-label="Scroll to top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          />
        </svg>
      </button>

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4 transition-colors duration-300">
            My Blog
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto transition-colors duration-300">
            Thoughts, learnings, and insights on web development, React, and
            more.
          </p>
        </div>

        {/* Search and Filter Section */}
        <div
          ref={searchRef}
          className="mb-12 max-w-3xl mx-auto opacity-0 transition-opacity duration-500"
        >
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-colors duration-300">
            <div className="relative mb-6">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search articles by keyword, tag, or category..."
                className="pl-10 pr-4 py-3 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 transition-colors duration-300"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="flex flex-wrap gap-2">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    selectedCategory === category
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600"
                  }`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Results Count */}
        <div className="mb-8 text-lg text-gray-600 dark:text-gray-300 transition-colors duration-300">
          {filteredBlogs.length === 0 ? (
            <p>No articles found. Try a different search term or category.</p>
          ) : (
            <p>
              Found {filteredBlogs.length} article
              {filteredBlogs.length !== 1 ? "s" : ""}
            </p>
          )}
        </div>

        {/* Blog Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredBlogs.map((blog, index) => (
            <div
              key={blog.id}
              ref={(el) => setBlogRef(index, el)}
              className={`bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden transform transition-all duration-700 ${
                visibleElements[index]
                  ? "translate-y-0 opacity-100"
                  : "translate-y-16 opacity-0"
              }`}
            >
              <div className="relative h-56 overflow-hidden">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
                />
                <div className="absolute top-0 right-0 mt-4 mr-4">
                  <span className="px-3 py-1 bg-blue-600 text-white text-xs font-semibold rounded-full">
                    {blog.category}
                  </span>
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-2 transition-colors duration-300">
                  <span>{blog.date}</span>
                  <span className="mx-2">•</span>
                  <span>{blog.readTime}</span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2 transition-colors duration-300">
                  {blog.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-3 transition-colors duration-300">
                  {blog.excerpt}
                </p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {blog.tags.map((tag) => (
                    <span
                      key={tag}
                      className="text-xs font-semibold px-2 py-1 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded transition-colors duration-300"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm text-gray-600 dark:text-gray-400 transition-colors duration-300">
                    By {blog.author}
                  </div>
                  <Link
                    to={blog.link}
                    className="inline-flex items-center text-blue-600 dark:text-blue-400 font-medium hover:text-blue-800 dark:hover:text-blue-300 transition-colors duration-300"
                  >
                    Read more
                    <svg
                      className="ml-1 w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Schema.org markup for SEO */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            headline: "My Blog",
            description:
              "Thoughts, learnings, and insights on web development, React, and more.",
            author: {
              "@type": "Person",
              name: "Rajat Saraswat",
            },
            blogPost: blogs.map((blog) => ({
              "@type": "BlogPosting",
              headline: blog.title,
              description: blog.excerpt,
              datePublished: blog.date,
              author: {
                "@type": "Person",
                name: blog.author,
              },
              image: blog.image,
            })),
          }),
        }}
      />
    </div>
  );
};

export default BlogPage;
